import PackageContainer from './_common/PackageContainer';
import SectionHeading from './_common/SectionHeading';
import SectionBody from './_common/SectionBody';
import Business from './_common/Business';

export default function BusinessBasic() {

    return ( 
        <PackageContainer>            
        <SectionHeading 
          title={'Basic'} 
          amount={'$750/mo'}
          subheading={'For businesses that have content ready to be seen'}
        />
        <SectionBody>
          <Business level="Basic" />
        </SectionBody>
      </PackageContainer>
    )
}