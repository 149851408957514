import ListItem from './ListItem';

export default function SEO({ level }) {
  const PageCount = [
    `${level === 'Basic' 
      ? 'Up to 8 pages' 
      : level === 'Standard' ?
        'Up to 15 pages'
        : 'Up to 30 pages'
      }
    `
  ]

  const Technical = [
    "Headers",
    "Content",
    "Page Title",
    "Meta"
  ]

  const allServices = [
    {
      title: "Onsite SEO",
      available: true,
      items: PageCount
    },
    {
      title: "Technical Optimization",
      available: true,
      items: Technical
    },
    {
      title: "Keyword Optimization - Setup",
      available: true
    },
    {
      title: "Monthly Performance Report",
      available: true,
    },
    {
      title: "Monthly Review Call",
      available: level !== "Basic",
    },
    {
      title: "One Blog Post per Month",
      available: level !== "Basic",
    },
    {
      title: "Google My Business Profile Management",
      available: level !== "Basic",
    },
    {
      title: "Ongoing Keyword Optimization & Research",
      available: level === "Full",
    },
    {
      title: "Local SEO",
      available: level === "Full",
    },
    {
      title: "Backlinks",
      available: level === "Full",
    },
    {
      title: "Quarterly Semrush Audit",
      available: level === "Full",
    },
  ]
  
 
  const renderList = allServices.map((item) => <ListItem available={item.available} title={item.title} items={item.items} />)
  
  return (            
    <ul className="w-full list-outside text-left flex flex-col gap-5">
     { renderList }
    </ul>            
  )
}