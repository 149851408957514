import '../../App.css';
import CircleButton from '../../components/_common/CircleButton';
import BusinessBasic from './components/BusinessBasic';
import BusinessStandard from './components/BusinessStandard';
import BusinessFull from './components/BusinessFull';
import SEOBasic from './components/SEOBasic';
import SEOStandard from './components/SEOStandard';
import SEOFull from './components/SEOFull';


function Packages() {

  return (
    <div className="Packages py-8 flex flex-col items-center gap-6">
      <h1 className="font-SpaceAge text-lg sm:text-2xl font-bold">Blxck<span className="yellow-stroke">marketing</span></h1>
      
      <CircleButton to={'/'} />

      <h2 className="font-Russo text-base sm:text-lg text-white/90 leading-none">Business Packages</h2>
      <section className="text-md h-full max-w-[1600px] py-10 px-10 flex flex-col gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 md:gap-6">
          <BusinessBasic />
          <BusinessStandard />
          <BusinessFull />  
        </div>
      </section>
      
      <h2 className="font-Russo text-base sm:text-lg text-white/90 leading-none">SEO Packages</h2>
      <section className="text-md h-full w-full max-w-[1600px] py-10 px-10 flex flex-col gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 md:gap-6">
          <SEOBasic />
          <SEOStandard />
          <SEOFull />
        </div>
      </section>
    </div>
  );
}

export default Packages;
