import PackageContainer from './_common/PackageContainer';
import SectionHeading from './_common/SectionHeading';
import SectionBody from './_common/SectionBody';
import SEO from './_common/SEO';

export default function SEOBasic() {

    return ( 
        <PackageContainer>            
            <SectionHeading 
              title={'Basic'} 
              amount={'$300/mo'}
            />
            <SectionBody>
              <SEO level="Basic" />
            </SectionBody>
        </PackageContainer>    
    )
}