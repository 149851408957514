import PackageContainer from './_common/PackageContainer';
import SectionHeading from './_common/SectionHeading';
import SectionBody from './_common/SectionBody';
import Business from './_common/Business';

export default function BusinessFull() {

    return ( 
        <PackageContainer>
            <SectionHeading 
                title={'Full'} 
                amount={'$3,000/mo'}
                subheading={'For businesses requiring full content creation, social media, & web development services'}
            />
            <SectionBody>
                <Business level="Full" />
            </SectionBody>
        </PackageContainer>            
    )
}