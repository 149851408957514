import { useState } from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { TbCircleChevronUp } from "react-icons/tb";
import Item from "./Item";

export default function ListItem ({ title, items: dropdownItems, available }) {
    const [isOpen, setIsOpen] = useState(false)

    const renderDropdownItems = dropdownItems ? 
        dropdownItems.map((item) => <li key={item}><BsArrowReturnRight className="inline mr-2 h-4 w-4" />{ item }</li>)
        : null

    const onToggleView = () => {
        setIsOpen(isOpen => !isOpen)
    }

    return ( <li>
        <span className="flex justify-between items-center gap-2">
            <Item available={available} text={ title } />
            { dropdownItems && <button disabled={!available} onClick={onToggleView}>
                <TbCircleChevronUp className={`inline float-right h-6 w-6 transition-all duration-300 ${isOpen ? "-rotate-180" : "rotate-0"} ${!available ? 'text-white/30' : 'text-white'}`} />
            </button> }
        </span>
        { renderDropdownItems && <ul className={`text-md px-6 md:pl-10 list-inside ${isOpen ? "visible" : "hidden"}`} >
            { renderDropdownItems }
        </ul> }
      </li>

    )
}
