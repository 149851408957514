import PackageContainer from './_common/PackageContainer';
import SectionHeading from './_common/SectionHeading';
import SectionBody from './_common/SectionBody';
import Business from './_common/Business';

export default function BusinessStandard() {

    return ( 
        <PackageContainer>
            <SectionHeading 
              title={'Standard'} 
              amount={'$1,650/mo'}
              subheading={'For businesses requiring moderate social media support'}
            />
            <SectionBody>
              <Business level="Standard" />
              <em>*Customized social media options are available for clients who don't need monthly email development or blog posting.</em>
            </SectionBody>
          </PackageContainer>
    )
}