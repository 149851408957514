import PackageContainer from './_common/PackageContainer';
import SectionHeading from './_common/SectionHeading';
import SectionBody from './_common/SectionBody';
import SEO from './_common/SEO';

export default function SEOStandard() {

    return ( 
        <PackageContainer>
            <SectionHeading 
              title={'Standard'} 
              amount={'$1,000/mo'}
              subheading={''}
            />
            <SectionBody>
              <SEO level="Standard" />
            </SectionBody>
        </PackageContainer>
    )
}