import ListItem from './ListItem';
import SectionSubHeading from './SectionSubHeading';

export default function Business({ level }) {
  const GoogleAds = [
    "Search Ads",
    "Performance Max Campaigns",
    "Google Map Ads",
    "Display Ads",
    "YouTube & Video Ads",
    "Shopping Ads",
    "Re-Marketing Ads"
  ]

  const SocialPlatformAds = [
    "Facebook",
    "Instagram",
    "LinkedIn",
    "TikTok",
    "YouTube"
  ]

  const EmailDevelopment = [
    `${level === 'Standard' 
      ? 'One custom designed email newsletter per month' 
      : 'Two custom designed email newsletters per month'
      }
    `
  ]

  const BlogPosting = [
    `${level === 'Standard' 
      ? 'One custom designed blog post per month' 
      : 'Two custom designed blog posts per month'
      }
    `
  ]

  const SocialPosting = [
    `${level === 'Standard' 
      ? 'One post per week' 
      : 'Two posts per week'
      }
    `
  ]

  const allServices = [
    {
        title: "Strategy",
        items: [

            {
              title: level === "Basic"? "Basic Brand Strategy" : "Brand Strategy",
              available: true,
            },
            {
              title: "Strategic Content Planning",
              available: level !== "Basic",
            },
            {
                title: "Creative Direction & Video Production Management",
                available: level === "Full"
            }
        ]
    },
    {
        title: "Advertising",
        items: [

            {
              title: "Google Ads",
              available: true,
              dropdownItems: GoogleAds
            },
            {
              title: "Social Platform Ads",
              available: true,
              dropdownItems: SocialPlatformAds
            },
        ]
    },
    {
        title: "Analytics",
        items: [

            {
              title: "Monthly Reporting",
              available: true,
            },
            {
              title: "Google Analytics Setup and Management",
              available: true,
            },
            {
              title: "Conversion Tracking & Data Analysis",
              available: true
            },
            {
              title: "Basic SEO Management",
              available: level === "Full",
              dropdownItems: [ "Advanced options available; see below"]
            }
        ]
    },
    {
        title: "Development",
        items: [

            {
              title: "Ad Asset Creation",
              available: level !== "Basic",
            },
            {
              title: "Graphic Design, Reel Capture & Content Curation",
              available: level !== "Basic",
            },
            {
              title: "Monthly Email Development",
              available: level !== "Basic",
              dropdownItems: EmailDevelopment
            },
            {
              title: "Monthly Blog Posting",
              available: level !== "Basic",
              dropdownItems: BlogPosting
            },
            {
                title: "Basic Website Maintenance & Management",
                available: level === "Full"
            }
        ]
    },
    {
        title: "Socials",
        items: [

            {
              title: "Social Posting",
              available: level !== "Basic",
              dropdownItems: SocialPosting
            },
            {
              title: "Post Scheduling & Client Approval Workflow",
              available: level !== "Basic",
            },
            {
              title: "Engagement",
              available: level !== "Basic"
            },
            {
              title: "Copywriting & Hashtag Management",
              available: level !== "Basic"
            }
        ]
    }
  ]
  
 
  const renderList = allServices.map((service) => {
    return <ul className="w-full list-outside text-left flex flex-col gap-5">
            <SectionSubHeading text={service.title} />
            {service.items.map((item) => <ListItem available={item.available} title={item.title} items={item.dropdownItems} />)}
        </ul>  
  })    
  
  return (<div>  { renderList } </div>)
}